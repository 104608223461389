import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormModalComponent } from './components/form-modal/form-modal.component';
import { FormsModule } from '@angular/forms';
import { FormAddTextForModelInferencingComponent } from './components/form-add-text-for-model-inferencing/form-add-text-for-model-inferencing.component';
import { ModelInferencingHistoryComponent } from './components/model-inferencing-history/model-inferencing-history.component';
import { TextRecordComponent } from './components/text-record/text-record.component';


@NgModule({
  declarations: [AppComponent,FormModalComponent,FormAddTextForModelInferencingComponent,ModelInferencingHistoryComponent,TextRecordComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule,ReactiveFormsModule,FormsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy}],
  bootstrap: [AppComponent],
})

// @NgModule({
//   declarations: [AppComponent],
//   imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule,ReactiveFormsModule,FormsModule],
//   providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy}],
//   bootstrap: [AppComponent],
// })
export class AppModule {}
